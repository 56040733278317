
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiFileExport } from '@mdi/js'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'
import { useGetProductIncrements } from '@/api/productIncrement'

export default defineComponent({
  name: 'CapacityExportMenu',
  components: {
    CommonAutocomplete,
  },
  inheritAttrs: false,
  setup: (_, { emit }) => {
    const { exec: getProductIncrements, data: productIncrements, isLoading } = useGetProductIncrements()

    getProductIncrements()

    const isMenuOpen = ref(false)

    const formElement = ref<HTMLFormElement | null>(null)

    const isFormValid = ref(false)

    const startPi = ref<number | null>(null)
    const endPi = ref<number | null>(null)

    const isPiSpanValid = computed(() => {
      if (isFormValid.value && startPi.value && endPi.value) {
        if (startPi.value - endPi.value <= 0) {
          return true
        }
      } else if (isFormValid.value && !startPi.value && !endPi.value) {
        return true
      }

      return false
    })

    function onExport(): void {
      emit('export', { startPi: startPi.value, endPi: endPi.value })
    }

    const hasCancelButtonFocus = ref(false)

    return reactive({
      formElement,
      icons: {
        mdiFileExport,
      },
      state: {
        isMenuOpen,

        isFormValid,
        isPiSpanValid,
        productIncrements,
        isLoading,

        startPi,
        endPi,

        hasCancelButtonFocus,
      },
      listeners: {
        onExport,
      },
    })
  },
})
