import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  CapacityProductIncrementEntryId,
  CapacityProductIncrementEntryInput,
  CapacityProductIncrementEntryOutput,
  UseCreateCapacityProductIncrementEntry,
  UseDeleteCapacityProductIncrementEntry,
  UseGetCapacityProductIncrementEntries,
  UseUpdateCapacityProductIncrementEntry,
} from './types/capacityProductIncrementEntry'

import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'capacity-product-increment-entry'

function useGetCapacityProductIncrementEntries<T>(): UseGetCapacityProductIncrementEntries<T> {
  const axios = useAxiosPaginated<T>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useCreateCapacityProductIncrementEntry(): UseCreateCapacityProductIncrementEntry {
  const axios = useAxios<
    PromiseType<ReturnType<UseCreateCapacityProductIncrementEntry['createCapacityProductIncrementEntry']>>
  >({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createCapacityProductIncrementEntry(
    data: CapacityProductIncrementEntryInput
  ): Promise<CapacityProductIncrementEntryOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createCapacityProductIncrementEntry,
  }
}

function useUpdateCapacityProductIncrementEntry(): UseUpdateCapacityProductIncrementEntry {
  const axios = useAxios<
    PromiseType<ReturnType<UseUpdateCapacityProductIncrementEntry['updateCapacityProductIncrementEntry']>>
  >({ method: 'PUT' })

  function updateCapacityProductIncrementEntry(
    id: CapacityProductIncrementEntryId,
    data: CapacityProductIncrementEntryOutput
  ): Promise<CapacityProductIncrementEntryOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateCapacityProductIncrementEntry,
  }
}

function useDeleteCapacityProductIncrementEntry(): UseDeleteCapacityProductIncrementEntry {
  const axios = useAxios<
    PromiseType<ReturnType<UseDeleteCapacityProductIncrementEntry['deleteCapacityProductIncrementEntry']>>
  >({ method: 'DELETE' })

  function deleteCapacityProductIncrementEntry(id: CapacityProductIncrementEntryId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteCapacityProductIncrementEntry,
  }
}

export {
  useGetCapacityProductIncrementEntries,
  useCreateCapacityProductIncrementEntry,
  useUpdateCapacityProductIncrementEntry,
  useDeleteCapacityProductIncrementEntry,
}
