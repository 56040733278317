
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { isEqual } from 'lodash-es'
import { mdiPencil, mdiChevronUp, mdiChevronDown, mdiFilter } from '@mdi/js'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CapacityExportMenu from '@/views/planning/views/capacity/components/CapacityExportMenu.vue'

import { useFilter } from '@/composables/useFilter'
import { useCapacity } from '@/views/planning/views/capacity/useCapacity'

import { handleError } from '@/utils/handleError'
import { isPercentageRange } from '@/utils/validation'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { convertCommaIntoDotInput, convertDotIntoCommaInput } from '@/utils/convertInput'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import {
  useExportCapacityProfUnitExtensions,
  useUpdateCapacityProfUnitExtension,
} from '@/api/capacityProfUnitExtension'
import { useGetEnumItemsBasic } from '@/api/enumItem'
import { useGetProfUnits } from '@/api/profUnit'

import { useUpdateCapacityProductIncrementEntry } from '@/api/capacityProductIncrementEntry'
import { useDeleteCapacityProfUnitExtension } from '@/api/capacityProfUnitExtension'

import { CapacityProfUnitExtensionOutput } from '@/api/types/capacityProfUnitExtension'
import { CapacityProductIncrementEntryOutput } from '@/api/types/capacityProductIncrementEntry'
import { ProfUnitOutput } from '@/api/types/profUnit'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/planning/views/capacity/types'
import { Rights } from '@/api/types/right'
import { useNotify } from '@/store'

interface DeductionFactors {
  deductionFactorFirstPi: string
  deductionFactorSecondPi: string
  deductionFactorThirdPi: string
}

export default defineComponent({
  name: 'Capacity',
  components: {
    CommonTableView,
    CapacityExportMenu,
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonAutocomplete: () => import('@/components/common/CommonAutocomplete.vue'),
    CommonNumberInput: () => import('@/components/common/CommonNumberInput.vue'),
    CapacityFilterBar: () => import('@/views/planning/views/capacity/components/CapacityFilterBar.vue'),
  },
  setup(_, { root }) {
    const { addNotification } = useNotify()

    const {
      capacityCols,
      getCapacities,
      capacities,
      isLoadingCapacities,
      paginationResponse,
      generateCapacityCols,
      CAPACITY_RELEVANT_PROPERTIES,
    } = useCapacity()

    const TABLE_HEADERS = computed(() => [
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.topLevelProfessionalUnit'),
        value: 'topLevelProfessionalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.secondLevelProfessionalUnit'),
        value: 'secondLevelProfessionalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.domain'),
        value: 'domain',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.professionalUnitShortName'),
        value: 'professionalUnitShortName',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.professionalUnitLongName'),
        value: 'professionalUnitLongName',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.professionalUnitTeamId'),
        value: 'professionalUnitTeamId',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.professionalUnitCategory'),
        value: 'professionalUnitCategory.name',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.status'),
        value: 'capacityStatus',
        sortable: false,
      },
      {
        text: root.$t('planning.capacity.table.col.title.deductionFactor'),
        value: 'deductionFactor',
        sortable: false,
      },
      {
        text: 'Kapazität',
        value: 'capacity',
        sortable: false,
      },
      ...capacityCols.value,
    ])

    const form = ref({} as CapacityProfUnitExtensionOutput)
    const deductionFactors = ref({} as DeductionFactors)

    const {
      exec: getCapacityTeamStatus,
      data: capacityTeamStatus,
      isLoading: isLoadingCapacityTeamStatus,
    } = useGetEnumItemsBasic()
    getCapacityTeamStatus({ params: { enumItemAssignment: 'CAPACITY_TEAM_STATUS' } })

    const { updateCapacityProfUnitExtension } = useUpdateCapacityProfUnitExtension()

    async function onAddedEditedStatus() {
      let updatedForm = mapBasicEntityToIdWithForm(form.value)
      updatedForm.deductionFactorFirstPi = convertCommaIntoDotInput(deductionFactors.value.deductionFactorFirstPi)
      updatedForm.deductionFactorSecondPi = convertCommaIntoDotInput(deductionFactors.value.deductionFactorSecondPi)
      updatedForm.deductionFactorThirdPi = convertCommaIntoDotInput(deductionFactors.value.deductionFactorThirdPi)

      try {
        await updateCapacityProfUnitExtension(updatedForm.id, updatedForm)
        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onOpenEditStatus(capacity: CapacityProfUnitExtensionOutput) {
      form.value = capacity
      deductionFactors.value.deductionFactorFirstPi = convertDotIntoCommaInput(capacity.deductionFactorFirstPi)
      deductionFactors.value.deductionFactorSecondPi = convertDotIntoCommaInput(capacity.deductionFactorSecondPi)
      deductionFactors.value.deductionFactorThirdPi = convertDotIntoCommaInput(capacity.deductionFactorThirdPi)
    }

    function getCellValue(
      item: CapacityProfUnitExtensionOutput,
      column: string
    ): null | { id: number; data: CapacityProductIncrementEntryOutput } {
      const capacityProductIncrementEntry = item.capacityProductIncrementEntries.find(
        (entry) => entry.totalProductIncrement === Number(column)
      )

      if (!capacityProductIncrementEntry) return null

      return {
        id: capacityProductIncrementEntry.id,
        data: capacityProductIncrementEntry,
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const piToEdit = ref<any>(null) // typing missing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const roundedPiToEdit = ref<any>(null) // typing missing

    const { updateCapacityProductIncrementEntry } = useUpdateCapacityProductIncrementEntry()

    function onOpenEditPi(item: CapacityProfUnitExtensionOutput, id: number): void {
      piToEdit.value = { ...item.capacityProductIncrementEntries.find((piEntry) => piEntry.id === id) }

      roundedPiToEdit.value = piToEdit.value
      roundedPiToEdit.value.plannedAssumedCapacity = convertDotIntoCommaInput(
        Number(piToEdit.value.plannedAssumedCapacity.toFixed(2))
      )
      roundedPiToEdit.value.plannedPhysicalCapacity = convertDotIntoCommaInput(
        Number(piToEdit.value.plannedPhysicalCapacity.toFixed(2))
      )

      piToEdit.value.plannedAssumedCapacity = convertDotIntoCommaInput(piToEdit.value.plannedAssumedCapacity)
      piToEdit.value.plannedPhysicalCapacity = convertDotIntoCommaInput(piToEdit.value.plannedPhysicalCapacity)
    }

    async function onAddedEditedPi(): Promise<void> {
      if (!piToEdit.value) return

      let updatedPiToEdit = mapBasicEntityToIdWithForm(piToEdit.value)

      updatedPiToEdit.plannedAssumedCapacity = convertCommaIntoDotInput(updatedPiToEdit.plannedAssumedCapacity)
      updatedPiToEdit.plannedPhysicalCapacity = convertCommaIntoDotInput(updatedPiToEdit.plannedPhysicalCapacity)

      try {
        await updateCapacityProductIncrementEntry(updatedPiToEdit.id, updatedPiToEdit)

        piToEdit.value = null

        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function getYearPi(headerText: string): number {
      return Number(headerText.split(' ')[0])
    }

    function piRules(value: number): string | boolean {
      const valueAsString = String(value)
      return isPercentageRange(valueAsString)
    }

    function getDeductionFactors(capacity: CapacityProfUnitExtensionOutput) {
      return [
        convertDotIntoCommaInput(Number(capacity.deductionFactorFirstPi.toFixed(2))),
        convertDotIntoCommaInput(Number(capacity.deductionFactorSecondPi.toFixed(2))),
        convertDotIntoCommaInput(Number(capacity.deductionFactorThirdPi.toFixed(2))),
      ]
    }

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!profUnits.value?.length && !enumItemsBasic.value?.length) {
          getProfUnits({ params: { size: 9999 } })
          getEnumItemsBasic({ params: { enumItemAssignment: 'PROFESSIONAL_UNIT_CATEGORY' } })
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const { exec: getProfUnits, data: profUnits, isLoading: isLoadingProfUnits } = useGetProfUnits<ProfUnitOutput>()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.TopUnitIds,
        value: null,
        label: root.$t('planning.capacity.filters.topUnitIds'),
        items: computed(() => profUnits.value.filter((profUnit) => profUnit.parent === null)),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Domain,
        value: null,
        label: root.$t('planning.capacity.filters.domain'),
        items: computed(() => profUnits.value.filter((profUnit) => profUnit.domain !== null)),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ShortName,
        value: null,
        label: root.$t('planning.capacity.filters.shortName'),
        items: computed(() => profUnits.value.filter((profUnit) => profUnit.shortName !== null)),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.Deletable,
        value: null,
        label: root.$t('planning.capacity.filters.deletable'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.LongName,
        value: null,
        label: root.$t('planning.capacity.filters.longName'),
        items: computed(() => profUnits.value),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.TeamId,
        value: null,
        label: root.$t('planning.capacity.filters.teamId'),
        items: computed(() => profUnits.value.filter((profUnit) => profUnit.teamId !== null)),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.CategoryIds,
        value: null,
        label: root.$t('planning.capacity.filters.categoryIds'),
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StatusIds,
        value: null,
        label: root.$t('planning.capacity.filters.statusIds'),
        items: computed(() => capacityTeamStatus.value ?? []),
        isLoading: computed(() => isLoadingCapacityTeamStatus.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    const totalCapacities = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getCapacities({ params: { ...paginationParams.value, ...filterFieldsObject } }).then(generateCapacityCols)
    }

    const isDeleteCapacityDialogOpen = ref(false)

    function onCloseDeleteDialog(): void {
      isDeleteCapacityDialogOpen.value = false
    }

    const { deleteCapacityProfUnitExtension } = useDeleteCapacityProfUnitExtension()

    const capacityToDelete = ref<null | CapacityProfUnitExtensionOutput>(null)

    function onClickDelete(capacity: CapacityProfUnitExtensionOutput): void {
      capacityToDelete.value = { ...capacity }
      isDeleteCapacityDialogOpen.value = true
    }

    async function onDeleteCapacity(): Promise<void> {
      if (!capacityToDelete.value) return

      try {
        await deleteCapacityProfUnitExtension(capacityToDelete.value?.id)

        onCloseDeleteDialog()
        capacityToDelete.value = null

        addNotification({
          text: root.$t('misc.deleted') as string,
          type: 'success',
          timeout: 3000,
        })

        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    const { exportCapacityProfUnitExtensions, isLoading: isLoadingExport } = useExportCapacityProfUnitExtensions()

    async function onExportCapacity(piIds: { startPi: number; endPi: number }) {
      try {
        await exportCapacityProfUnitExtensions({
          startPi: piIds.startPi,
          endPi: piIds.endPi,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    return reactive({
      icons: {
        mdiPencil,
        mdiChevronUp,
        mdiChevronDown,
        mdiFilter,
      },
      constants: {
        TABLE_HEADERS,

        CAPACITY_RELEVANT_PROPERTIES,

        Rights,

        FILTER_FIELDS,
      },
      state: {
        capacities,
        isLoadingCapacities,
        form,
        deductionFactors,
        capacityCols,

        capacityTeamStatus,
        isLoadingCapacityTeamStatus,

        vuetifyTableOptions,

        totalCapacities,

        piRules,
        piToEdit,
        roundedPiToEdit,

        isFilterDropdownOpen,

        isDeleteCapacityDialogOpen,
        isLoadingExport,
      },
      functions: {
        debouncedCb,

        getCapacities,
        getDeductionFactors,

        getCellValue,
        getYearPi,

        convertDotIntoCommaInput,

        hasSufficientRights,
      },
      listeners: {
        onAddedEditedStatus,

        onOpenEditStatus,

        onOpenEditPi,
        onAddedEditedPi,

        onToggleFilterDropdown,

        onCloseDeleteDialog,
        onDeleteCapacity,
        onClickDelete,

        onExportCapacity,
      },
    })
  },
})
