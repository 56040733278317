var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "offset-y": "",
      "close-on-content-click": false,
      "max-width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rounded-lg white",
          attrs: {
            "text": "",
            "color": "primary",
            "elevation": "0"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFileExport) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.export.title')) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.state.isMenuOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isMenuOpen", $$v);
      },
      expression: "state.isMenuOpen"
    }
  }, [_c('v-card', {
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.state.isMenuOpen = false;
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.state.hasCancelButtonFocus ? null : _vm.listeners.onExport();
      }]
    }
  }, [_c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-form', {
    ref: "formElement",
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.state.productIncrements,
      "item-text": "nr",
      "item-value": "id",
      "dense": "",
      "outlined": "",
      "loading": _vm.state.isLoading,
      "label": _vm.$t("planning.capacity.export.startPi")
    },
    model: {
      value: _vm.state.startPi,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "startPi", $$v);
      },
      expression: "state.startPi"
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.state.productIncrements,
      "item-text": "nr",
      "item-value": "id",
      "dense": "",
      "outlined": "",
      "loading": _vm.state.isLoading,
      "label": _vm.$t("planning.capacity.export.endPi")
    },
    model: {
      value: _vm.state.endPi,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "endPi", $$v);
      },
      expression: "state.endPi"
    }
  })], 1), !_vm.state.isPiSpanValid && _vm.state.isFormValid ? _c('span', {
    staticClass: "text-subtitle-3 red--text mt-2 d-block"
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.capacity.export.invalidTimeSpan')) + " ")]) : _vm._e()], 1), _c('v-card-actions', [_c('v-btn', _vm._b({
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "disabled": !_vm.state.isPiSpanValid && _vm.state.isFormValid,
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onExport();
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = false;
      }
    }
  }, 'v-btn', _vm.$attrs, false), [_vm._v(" " + _vm._s(_vm.$t('misc.export.actions.export')) + " ")]), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "text": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.hasCancelButtonFocus ? _vm.state.isMenuOpen = false : null;
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('misc.export.actions.close')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }