var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.capacities,
      "loading": _vm.state.isLoadingCapacities,
      "with-info": false,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalCapacities,
      "is-editable": false,
      "is-full-width": ""
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "my-4 ma-0 rounded-lg",
          attrs: {
            "align": "center"
          }
        }, [_c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "elevation": "0",
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.listeners.onToggleFilterDropdown
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1), _c('CapacityExportMenu', {
          attrs: {
            "loading": _vm.state.isLoadingExport
          },
          on: {
            "export": function _export($event) {
              return _vm.listeners.onExportCapacity($event);
            }
          }
        })], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('CapacityFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [!item.deletable ? _c('div') : _vm._e()];
      }
    }, _vm._l(_vm.state.capacityCols, function (capacityCol) {
      return {
        key: "header.".concat(capacityCol.value),
        fn: function fn(_ref2) {
          var header = _ref2.header;
          return [_c('div', {
            key: capacityCol.value,
            staticClass: "d-flex flex-column pt-2"
          }, [_vm.functions.getYearPi(header.text) === 3 ? _c('span', [_vm._v(" " + _vm._s(header.meta.year) + " ")]) : _c('span', {
            staticClass: "header-placeholder"
          }), _c('span', {
            staticClass: "my-5"
          }, [_vm._v(" " + _vm._s(header.text) + " ")])])];
        }
      };
    }), {
      key: "capacityStatus",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm.functions.hasSufficientRights(_vm.constants.Rights.CAPACITY_UPDATE) ? _c('v-edit-dialog', {
          attrs: {
            "large": "",
            "cancel-text": _vm.$t("planning.capacity.form.cancel"),
            "save-text": _vm.$t("planning.capacity.form.save")
          },
          on: {
            "save": function save($event) {
              return _vm.listeners.onAddedEditedStatus();
            },
            "open": function open($event) {
              return _vm.listeners.onOpenEditStatus(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('CommonAutocomplete', {
                attrs: {
                  "items": _vm.state.capacityTeamStatus,
                  "item-text": "name",
                  "item-value": "id",
                  "loading": _vm.state.isLoadingCapacityTeamStatus,
                  "label": _vm.$t("planning.capacity.form.status")
                },
                model: {
                  value: _vm.state.form.status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.state.form, "status", $$v);
                  },
                  expression: "state.form.status"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('v-container', {
          staticClass: "pa-0 my-1"
        }, [_c('v-row', [_c('v-col', {
          staticStyle: {
            "min-width": "130px"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.status.name) + " ")])]), _c('v-col', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1)], 1)], 1)], 1) : _c('v-container', {
          staticClass: "pa-0 my-1"
        }, [_c('v-row', [_c('v-col', {
          staticStyle: {
            "min-width": "130px"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.status.name) + " ")])])], 1)], 1)];
      }
    }, {
      key: "capacity",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', {
          staticClass: "py-2 capacity"
        }, [_vm._v("Physisch (ist)")]), _c('div', {
          staticClass: "py-2 capacity"
        }, [_vm._v("Kapazität (ist)")]), _c('div', {
          staticClass: "py-2 capacity"
        }, [_vm._v("Physisch (plan)")]), _c('div', {
          staticClass: "py-2 capacity"
        }, [_vm._v("Kapazität (plan)")])])];
      },
      proxy: true
    }, _vm._l(_vm.state.capacityCols, function (capacityCol) {
      return {
        key: "item.".concat(capacityCol.value),
        fn: function fn(_ref4) {
          var _vm$functions$getCell;

          var item = _ref4.item;
          return _vm._l(Object.entries(((_vm$functions$getCell = _vm.functions.getCellValue(item, capacityCol.value)) === null || _vm$functions$getCell === void 0 ? void 0 : _vm$functions$getCell.data) || []), function (entry) {
            return _c('div', {
              key: entry[0],
              staticClass: "grid-line-vertical-container"
            }, [_c('div', {
              class: {
                'grid-line-vertical': capacityCol.meta.yearProductIncrement === 5
              }
            }, [_vm.constants.CAPACITY_RELEVANT_PROPERTIES.includes(entry[0]) ? _c('div', {
              staticClass: "capacity-column grid-line-horizontal"
            }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.CAPACITY_UPDATE) && (entry[0] === 'plannedPhysicalCapacity' || entry[0] === 'plannedAssumedCapacity') ? _c('v-edit-dialog', {
              attrs: {
                "large": "",
                "cancel-text": _vm.$t("planning.capacity.form.cancel"),
                "save-text": _vm.$t("planning.capacity.form.save")
              },
              on: {
                "save": function save($event) {
                  return _vm.listeners.onAddedEditedPi();
                },
                "open": function open($event) {
                  _vm.listeners.onOpenEditPi(item, _vm.functions.getCellValue(item, capacityCol.value).id);
                }
              },
              scopedSlots: _vm._u([{
                key: "input",
                fn: function fn() {
                  return [_vm.state.piToEdit ? _c('CommonNumberInput', {
                    attrs: {
                      "value": _vm.state.piToEdit[entry[0]],
                      "label": _vm.$t("planning.capacity.form.piValue"),
                      "is-required": true
                    },
                    on: {
                      "change": function change($event) {
                        _vm.state.piToEdit[entry[0]] = $event;
                      }
                    },
                    model: {
                      value: _vm.state.roundedPiToEdit[entry[0]],
                      callback: function callback($$v) {
                        _vm.$set(_vm.state.roundedPiToEdit, entry[0], $$v);
                      },
                      expression: "state.roundedPiToEdit[entry[0]]"
                    }
                  }) : _vm._e()];
                },
                proxy: true
              }], null, true)
            }, [_c('div', {
              staticClass: "capacity-cell grey lighten-2"
            }), _c('span', {
              staticClass: "capacity-cell__value"
            }, [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(entry[1].toFixed(1))) + " ")])]) : _c('span', [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(entry[1].toFixed(1))) + " ")])], 1) : _vm._e()])]);
          });
        }
      };
    }), {
      key: "deductionFactor",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm.functions.hasSufficientRights(_vm.constants.Rights.CAPACITY_UPDATE) ? _c('v-edit-dialog', {
          attrs: {
            "large": "",
            "cancel-text": _vm.$t("planning.capacity.form.cancel"),
            "save-text": _vm.$t("planning.capacity.form.save")
          },
          on: {
            "save": function save($event) {
              return _vm.listeners.onAddedEditedStatus();
            },
            "open": function open($event) {
              return _vm.listeners.onOpenEditStatus(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('CommonNumberInput', {
                attrs: {
                  "label": _vm.$t("planning.capacity.form.deductionFactorFirstPi"),
                  "rules": [].concat(_vm.state.piRules),
                  "is-required": true
                },
                model: {
                  value: _vm.state.deductionFactors.deductionFactorFirstPi,
                  callback: function callback($$v) {
                    _vm.$set(_vm.state.deductionFactors, "deductionFactorFirstPi", $$v);
                  },
                  expression: "state.deductionFactors.deductionFactorFirstPi"
                }
              }), _c('CommonNumberInput', {
                attrs: {
                  "label": _vm.$t("planning.capacity.form.deductionFactorSecondPi"),
                  "rules": [].concat(_vm.state.piRules),
                  "is-required": true
                },
                model: {
                  value: _vm.state.deductionFactors.deductionFactorSecondPi,
                  callback: function callback($$v) {
                    _vm.$set(_vm.state.deductionFactors, "deductionFactorSecondPi", $$v);
                  },
                  expression: "state.deductionFactors.deductionFactorSecondPi"
                }
              }), _c('CommonNumberInput', {
                attrs: {
                  "label": _vm.$t("planning.capacity.form.deductionFactorThirdPi"),
                  "rules": [].concat(_vm.state.piRules),
                  "is-required": true
                },
                model: {
                  value: _vm.state.deductionFactors.deductionFactorThirdPi,
                  callback: function callback($$v) {
                    _vm.$set(_vm.state.deductionFactors, "deductionFactorThirdPi", $$v);
                  },
                  expression: "state.deductionFactors.deductionFactorThirdPi"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('v-container', {
          staticClass: "pa-0 my-1"
        }, [_c('v-row', [_c('v-col', {
          staticClass: "d-flex flex-column",
          staticStyle: {
            "min-width": "100px"
          }
        }, _vm._l(_vm.functions.getDeductionFactors(item), function (deductionFactor, index) {
          return _c('span', {
            key: index,
            staticClass: "my-1"
          }, [_vm._v(" " + _vm._s(index + 1) + ". PI: " + _vm._s(deductionFactor) + " ")]);
        }), 0), _c('v-col', {
          staticClass: "align-self-center"
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1)], 1)], 1)], 1) : _c('v-container', {
          staticClass: "pa-0 my-1"
        }, [_c('v-row', [_c('v-col', {
          staticClass: "d-flex flex-column",
          staticStyle: {
            "min-width": "100px"
          }
        }, _vm._l(_vm.functions.getDeductionFactors(item), function (deductionFactor, index) {
          return _c('span', {
            key: index,
            staticClass: "my-1"
          }, [_vm._v(" " + _vm._s(index + 1) + ". PI: " + _vm._s(deductionFactor) + " ")]);
        }), 0)], 1)], 1)];
      }
    }], null, true)
  }, [_vm.state.isDeleteCapacityDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteCapacity();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseDeleteDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteCapacityDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteCapacityDialogOpen", $$v);
      },
      expression: "state.isDeleteCapacityDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.capacity.deleteCapacity.text')) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }