enum FILTER_FIELD_KEY {
  TopUnitIds = 'topUnitIds',
  SecondUnitIds = 'secondUnitIds',
  Domain = 'domain',
  ShortName = 'shortName',
  Deletable = 'deletable',
  LongName = 'longName',
  TeamId = 'teamId',
  CategoryIds = 'categoryIds',
  StatusIds = 'statusIds',
}

export { FILTER_FIELD_KEY }
